import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useMemberCreate() {
  const toast = useToast()
  
  const fetchCounter = async () => {
    try {
      const response = await store.dispatch('app-member/fetchCounter');
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error add member.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }
  
  const updateCounter = async (counter) => {
    try {
      const response = await store.dispatch('app-member/updateCounter', counter);
      return response.data;
    } catch (error) {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error add member.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  }

  return {
    toast,
    fetchCounter,
    updateCounter,
  }
}
