<template>
  <div>
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col sm="12" md="8">
            <b-card>
              <b-row>
                <b-col sm="12" md="4">
                  <b-form-group>
                    <label for="employee">No. Employees <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="employee count"
                      rules="required|integer"
                    >
                      <b-form-input
                        v-model="employee"
                        :state="errors.length > 0 ? false : null"
                        placeholder="No. Employees"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="4">
                  <b-form-group>
                    <label for="country">Country of origin <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="country of origin"
                      rules="required|integer"
                    >
                      <b-form-input
                        v-model="country"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Country of origin"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="4">
                  <b-form-group>
                    <label for="lifestyle">Lifestyles <span class="text-red">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="lifestyle"
                      rules="required|integer"
                    >
                      <b-form-input
                        v-model="lifestyle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Lifestyles"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <div class="fixed-footer justify-content-end">
      <b-button variant="outline-secondary" @click="() => this.$router.go(0)">
        <span>Cancel</span>
      </b-button>
      <b-button
        class="ml-1"
        variant="success"
        type="submit"
        @click.prevent="validationForm"
      >
        <span>Save</span>
      </b-button>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BFormRadioGroup,
  BFormRadio,
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BTab,
  BTabs,
  BFormTextarea,
} from "bootstrap-vue";
import { required, email, regex, integer } from "@validations";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import useCounterBar from "./useCounterBar";
import memberStoreModule from "../teamStoreModule";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormRadioGroup,
    BFormRadio,
    BButton,
    BForm,
    BImg,
    BFormTextarea,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BTab,
    BTabs,

    vSelect,
  },
  data() {
    return {
      required,
      regex,
      email,
      integer,
      loading: false,
      employee: '',
      country: '',
      lifestyle: '',
    };
  },
  methods: {
    async validationForm() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;

      const { status } = await this.updateCounter({
        employees: this.employee,
        country: this.country,
        lifestyles: this.lifestyle,
      });
      const isSuccess = status === 200;
      this.makeToast(isSuccess);
    },
    makeToast(success) {
      this.$bvToast.toast(
        <div class="d-flex">
          <feather-icon
            class={success ? "text-success" : "text-danger"}
            icon={success ? "CheckCircleIcon" : "XCircleIcon"}
            size="24"
          />
          <div class="d-flex flex-column">
            <h4 class={success ? "ml-1 text-success" : "ml-1 text-danger"}>
              {success ? "Update succeeded!" : "Update failed!"}
            </h4>
            <div class="ml-1 font-14px">
              {success ? `Counter bar has been updated.` : "Can not update counter bar."}
            </div>
          </div>
        </div>,
        {
          headerClass: "d-none",
          solid: true,
        }
      );
    },
  },
  async created() {
    const app = document.getElementById('app')
    const appLoading = document.getElementById('loading-bg')
    const appLoadingImage = document.querySelector('#loading-bg > img')
    if (appLoading) {
      app.style.overflow = 'hidden'
      appLoading.style.zIndex = '9'
      appLoading.style.display = 'block'
      appLoadingImage.src = `/loading.gif?${Math.random()}`
    }
    try {
      const { data } = await this.fetchCounter();
      this.employee = data.employees;
      this.country = data.country;
      this.lifestyle = data.lifestyles;
      if (appLoading) {
        app.style.overflow = 'auto'
        appLoading.style.display = 'none'
      }
    } catch (error) {
      this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching content',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    }
  },
  setup() {
    const GALLERY_APP_STORE_MODULE_NAME = "app-member";

    // Register module
    if (!store.hasModule(GALLERY_APP_STORE_MODULE_NAME))
      store.registerModule(GALLERY_APP_STORE_MODULE_NAME, memberStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(GALLERY_APP_STORE_MODULE_NAME))
        store.unregisterModule(GALLERY_APP_STORE_MODULE_NAME);
    });

    const {
      toast,
      fetchCounter,
      updateCounter,
    } = useCounterBar();

    return {
      toast,
      fetchCounter,
      updateCounter,
    };
  },
};
</script>
<style lang="scss" scoped>
.avatar {
  max-width: 120px;
  max-height: 120px;
}
</style>