var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"8"}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"employee"}},[_vm._v("No. Employees "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"employee count","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"No. Employees"},model:{value:(_vm.employee),callback:function ($$v) {_vm.employee=$$v},expression:"employee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"country"}},[_vm._v("Country of origin "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"country of origin","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Country of origin"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"lifestyle"}},[_vm._v("Lifestyles "),_c('span',{staticClass:"text-red"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"lifestyle","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Lifestyles"},model:{value:(_vm.lifestyle),callback:function ($$v) {_vm.lifestyle=$$v},expression:"lifestyle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"fixed-footer justify-content-end"},[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function () { return this$1.$router.go(0); }}},[_c('span',[_vm._v("Cancel")])]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',[_vm._v("Save")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }